import React, { Component } from 'react'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { LanguageSwitcher } from '../i18n'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProps {}

interface IState {
  defaultLanguage: string
  selectedLanguage: string
}

export default class Header extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      defaultLanguage: 'English',
      selectedLanguage: '',
    }
  }

  onChangeLanguage(language: string) {
    this.setState({ selectedLanguage: language })
  }

  render() {
    return (
      <div style={{ paddingBottom: '30px' }}>
        <Navbar bg='light' expand='lg'>
          <Navbar.Brand href='/'>TMA</Navbar.Brand>
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='ml-auto'>
              <Nav.Link href='/Competency'>Competency</Nav.Link>
              <NavDropdown
                title='Interview Questions'
                id='interview-question-dropdown'
                aria-haspopup='true'
                aria-expanded='false'
                dropdown-toggle='dropdown'
              >
                <NavDropdown.Item href='/Competency'>
                  Competency Interview
                </NavDropdown.Item>
                <NavDropdown.Item href='/Social'>
                  Social Interview
                </NavDropdown.Item>
                <NavDropdown.Item href='/Reference'>
                  Reference Interview
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href='/Download'>Download</Nav.Link>
              <Nav.Link href='/Contact'>Contact</Nav.Link>
              <LanguageSwitcher />
              {/* <NavDropdown
                id='language-dropdown'
                className='dropdown-menu-right'
                title={
                  this.state.selectedLanguage || this.state.defaultLanguage
                }
                alignRight={true}
              >
                {[
                  { key: 'en', val: 'English' },
                  { key: 'nl', val: 'Netherlands' },
                ].map((v) => {
                  return (
                    <NavDropdown.Item
                      key={v.key}
                      href='#'
                      onClick={() => this.onChangeLanguage(v.val)}
                    >
                      {v.val}
                    </NavDropdown.Item>
                  )
                })}
              </NavDropdown> */}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    )
  }
}
