import React, { useState, useEffect } from 'react'

import { NavLink, useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
// eslint-disable-next-line import/no-unresolved
import { AppLanguage } from '../../../const'
import { NavDropdown } from 'react-bootstrap'
import { appStrings } from '..'

//import { appStrings } from '../index'

export const LanguageSwitcher: React.FC = () => {
  const { pathname } = useLocation()
  const { locale, messages } = useIntl()
  const [languageDropdownTitle, setLanguageDropdownTitle] = useState(locale)

  useEffect(() => {
    setLanguageDropdownTitle(
      Object.keys(AppLanguage).filter(
        (x: any) => AppLanguage[x] == locale
      )[0] || ''
    )
  }, [locale])

  return (
    <NavDropdown
      id='language-dropdown'
      className='dropdown-menu-right'
      alignRight={true}
      title={languageDropdownTitle}
    >
      {Object.keys(AppLanguage).map((lang) => (
        <NavDropdown.Item key={lang}>
          <NavLink to={getMatchingRoute(AppLanguage[lang])}>{lang}</NavLink>
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  )

  function getMatchingRoute(language: string) {
    /**
     * Get the key of the route the user is currently on
     */
    //const [, route] = pathname.split(locale)[1]
    const route =
      pathname.split(locale).length > 1
        ? pathname.substr(pathname.indexOf(locale) + 2)
        : pathname.split(locale)[1]
    let routeKey = Object.keys(messages).find((key) => messages[key] === route)

    /**
     * Find the matching route for the new language
     */
    let matchingRoute = appStrings[language][routeKey]

    if (typeof routeKey === 'undefined') {
      const arr_route = route.split('/')
      Object.keys(messages).find((key) => {
        if (messages[key].indexOf(arr_route[1]) > 0) {
          const arr_messageKey = messages[key].split('/')
          const messageKey_length = arr_messageKey.length
          if (messageKey_length == arr_route.length) {
            const route_key = appStrings[language][key].split('/')
            for (let i = 0; i < messageKey_length; i++) {
              if (arr_messageKey[i].indexOf(':') == -1) {
                arr_route[i] = route_key[i]
              }
            }
            return true
          }
          return false
        }
      })
      matchingRoute = arr_route.join('/')
    }

    /**
     * Return localized route
     */
    return `/${language}` + matchingRoute
  }
}
