import { FC } from 'react'
import { Accordion, Button, Card, ListGroup } from 'react-bootstrap'

interface propType {
  title: string
  items?: Array<string>
  bgClassName?: string
  titleTextColor?: string
}

const CompetencyDetailAccordian: FC<propType> = (props) => {
  const elementId = props.title.replace(' ', '_')
  return (
    <div>
      <Accordion>
        <Card>
          <Card.Header className={props.bgClassName}>
            <Accordion.Toggle
              as={Button}
              variant='link'
              eventKey={elementId}
              style={{ color: props.titleTextColor || '#000' }}
            >
              {props.title}
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey={elementId}>
            <Card.Body>
              {props.children ? (
                props.children
              ) : (
                <ListGroup>
                  {props.items?.map((item: string) => (
                    <ListGroup.Item key={item}>{item}</ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  )
}

export default CompetencyDetailAccordian
