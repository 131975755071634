import { LanguageStrings } from './base-strings'
import _ from 'underscore'
import jsonData from '../../../data/comeptency-detail-en.json'

const nlStrings: LanguageStrings = {
  /** Routes */
  'routes.home': '/',
  'routes.competency': '/competentie/:id',

  /** Page content */
  'home.title': 'Početna',
  'home.content':
    'Hvala vam što ste posjetili ovaj primjer višejezičnog routinga.',

  'lbl.competency': 'competentie',
  'competency.title': 'Competentie',
  'competency.content': 'Pročitajte više o članku na {medium} ili {devto}.',

  'error.title': 'Greška',
  'error.content':
    'Ova stranice je otišla na godišnji odmor. Pokušajte ponovo kasnije.',

  /** Links */
  'links.medium':
    'https://medium.com/prototyped/multi-language-routing-in-react-d7eb7a0688e9',
  'links.dev.to': 'https://dev.to/prototyp/multi-language-routing-in-react-k9l',
}
jsonData.Competencies['nl'].forEach((item: any) => {
  const competencyId: string = item.CompetencyID.toString()
  nlStrings[
    `routes.competency.${competencyId}`
  ] = `/${nlStrings['lbl.competency']}/${item.Name}`
})

export const nl = nlStrings
