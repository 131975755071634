import React, { useEffect, useState } from 'react'
import { ListGroup } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import jsonData from '../../../data/comeptency-detail-en.json'
import { TMA_colors } from '../../../theme'
import CompetencyDetail from '../CompetencyDetail/CompetencyDetail'
import './Home.css'

const Home: React.FC = (props: any) => {
  const { formatMessage, locale } = useIntl()
  const [competencies, setCompetencies] = useState<any[]>([])
  const [searchText, setSearchText] = useState<string>('')
  let circle_color_index = 0

  useEffect(() => {
    console.log('effect called')
    setCompetencies(
      jsonData.Competencies[`${locale}`].filter((x: any) => {
        const searchValue = x.Name.toLowerCase()
        return searchValue.indexOf(searchText) !== -1
      })
    )
  }, [searchText, locale])

  function goToCompetencyDetail(name: any) {
    props.history.push(
      `/${locale}/${formatMessage({ id: 'lbl.competency' })}/${name}`
    )
    // props.history.push({
    //   pathname: `/${locale}${formatMessage({
    //     id: 'routes.competency',
    //   })}`,
    //   search: { id: name },
    // })
  }

  return (
    <>
      <input
        type='text'
        className='form-control'
        placeholder='Search'
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <ListGroup style={{ marginTop: '30px' }}>
        {competencies.map((competency: any) => {
          const listItem = (
            <ListGroup.Item
              action
              key={competency.Name}
              // onClick={() => {
              //   goToCompetencyDetail(competency.Name)
              // }}
              href={`/${locale}/${formatMessage({ id: 'lbl.competency' })}/${
                competency.Name
              }`}
            >
              <div className='d-flex flex-row'>
                <div className='p-2'>
                  <div
                    className='circle'
                    style={{
                      backgroundColor: TMA_colors[circle_color_index],
                      padding: '5px',
                    }}
                  >
                    <div className='text-center circle-inner-letter'>
                      {competency.Name[0]}
                    </div>
                  </div>
                </div>
                <div className='p-2 flex-fill'>
                  <h4 className='text-semibold'>{competency.Name}</h4>
                  <p className='text-light-gray'>{competency.Description}</p>
                </div>
              </div>
            </ListGroup.Item>
          )

          circle_color_index++
          circle_color_index = circle_color_index == 8 ? 0 : circle_color_index

          return listItem
        })}
      </ListGroup>
    </>
  )
}
export default Home
