import React, { useState, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import jsonData from '../../../data/comeptency-detail-en.json'
import CustomAccordian from '../../../components/Accordian/CustomAccordian'
import { useIntl } from 'react-intl'

export class Competency {
  CompetencyID?: number
  LanguageID?: number
  Name?: string
  Description?: string
  GeneralBehavioralExample?: string
  OperationalBehavioralExample?: string
  TacticalBehavioralExample?: string
  StrategicalBehavioralExample?: string
  DevelopmentPotential?: string
  InterviewQuestions?: string
  DevelopmentActivities?: string
  CoachingAdvice?: string
  BehavioralExampleTitle?: string
  GeneralTitle?: string
  OperationalTitle?: string
  TacticalTitle?: string
  StrategicalTitle?: string
  DevelopmentPotentialTitle?: string
  InterviewQuestionsTitle?: string
  DevelopmentActivitiesTitle?: string
  CoachingAdviceTitle?: string
}

const CompetencyDetail: React.FC = (props: any) => {
  const { locale } = useIntl()
  const [competency, setCompetency] = useState<Competency>(new Competency())

  useEffect(() => {
    let data = jsonData.Competencies[locale || 'en'].filter(
      (x: Competency) => x.Name == props.match.params.id
    )[0]
    if (!data) {
      const existLang = Object.keys(jsonData.Competencies).find((lang) => {
        return (
          typeof jsonData.Competencies[lang].filter(
            (comp) => comp.Name == props.match.params.id
          )[0] !== 'undefined'
        )
      })
      data = jsonData.Competencies[existLang || 'en'].filter(
        (x: Competency) => x.Name == props.match.params.id
      )[0]
    }
    setCompetency(data || new Competency())
  }, [locale, props.match.params.id])

  return (
    <>
      <Col lg={12}>
        <div className='col-lg-12 padding-xs-vr'>
          <h5>
            <strong>{competency.Name}</strong>
          </h5>
          <p>{competency.Description}</p>
        </div>
        <div className='accordion' id='accordion-example'>
          <CustomAccordian
            title={competency.BehavioralExampleTitle || ''}
            bgClassName='bg-success'
            titleTextColor='white'
          >
            <CustomAccordian
              title={competency.GeneralTitle || ''}
              bgClassName='outline-secondary'
              items={competency.GeneralBehavioralExample?.split('|') || []}
            ></CustomAccordian>
            <CustomAccordian
              title={competency.OperationalTitle || ''}
              bgClassName='outline-secondary'
              items={competency.OperationalBehavioralExample?.split('|') || []}
            ></CustomAccordian>
            <CustomAccordian
              title={competency.TacticalTitle || ''}
              bgClassName='outline-secondary'
              items={competency.TacticalBehavioralExample?.split('|') || []}
            ></CustomAccordian>
            <CustomAccordian
              title={competency.StrategicalTitle || ''}
              bgClassName='outline-secondary'
              items={competency.StrategicalBehavioralExample?.split('|') || []}
            ></CustomAccordian>
          </CustomAccordian>
          <CustomAccordian
            title={competency.DevelopmentPotentialTitle || ''}
            bgClassName='outline-secondary'
            items={competency.DevelopmentPotential?.split('|') || []}
          ></CustomAccordian>
          <CustomAccordian
            title={competency.InterviewQuestionsTitle || ''}
            bgClassName='outline-secondary'
            items={competency.InterviewQuestions?.split('|') || []}
          ></CustomAccordian>
          <CustomAccordian
            title={competency.DevelopmentActivitiesTitle || ''}
            bgClassName='outline-secondary'
            items={competency.DevelopmentActivities?.split('|') || []}
          ></CustomAccordian>
          <CustomAccordian
            title={competency.CoachingAdviceTitle || ''}
            bgClassName='outline-secondary'
            items={competency.CoachingAdvice?.split('|') || []}
          ></CustomAccordian>
        </div>
      </Col>
    </>
  )
}

export default CompetencyDetail
//export default withRouter(CompetencyDetail)
