import React from 'react'
import { Container, Row } from 'react-bootstrap'
import Header from '../Header'

export const AppLayout: React.FC = ({ children }) => (
  <Container fluid={'lg'}>
    {/* <Nav>
        <Navigation />
        <LanguageSwitcher />
      </Nav> */}
    <Header />
    {children}
  </Container>
)
