import ReactDOM from 'react-dom'
import { BrowserRouter, Redirect, Route } from 'react-router-dom'
import { AppRoute, AppLanguage } from './const'

import 'bootstrap/dist/css/bootstrap.min.css'
import { LocalizedRouter, LocalizedSwitch, appStrings } from './modules/i18n'
import { AppLayout } from './modules/Layout'
import CompetencyDetail from './views/pages/CompetencyDetail/CompetencyDetail'
import Home from './views/pages/Index/Home'

ReactDOM.render(
  // <Router history={history}>
  //   <Header />
  //   <Switch>
  //     <Route
  //       exact
  //       path='/Competency/:id'
  //       render={({ match }) => <CompetencyDetail id={match.params.id} />}
  //     />
  //     <Route path='/' component={Home} />
  //   </Switch>
  // </Router>,
  <LocalizedRouter
    RouterComponent={BrowserRouter}
    languages={AppLanguage}
    appStrings={appStrings}
    defaultLanguage={AppLanguage['Netherlands']}
  >
    <AppLayout>
      <LocalizedSwitch>
        <Route exact path={AppRoute.Home} component={Home}></Route>
        <Route
          exact
          path={AppRoute.Competency}
          render={(props: any) => <CompetencyDetail {...props} />}
        ></Route>
        <Redirect to={`/:lang`} />
      </LocalizedSwitch>
    </AppLayout>
  </LocalizedRouter>,
  document.getElementById('root')
)
