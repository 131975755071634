export enum AppLanguage {
  English = 'en',
  Netherlands = 'nl',
  //Dansk = 'da',
  Deutch = 'de',
  French = 'fr',
  // Espanol = 'es',
  // Portuguese = 'pt',
  // Arabic = 'ar',
  // Chinese = 'zh',
  // Polish = 'pl',
  // Russian = 'ru',
}
