export const TMA_colors = [
  '#ee7352', //orange
  '#afcc46', //light_green
  '#d24291', //purple
  '#fab400', //yellow
  '#50b47e', //dark_green
  '#0085C8', //dark_blue
  '#5cc0c4', //sky_blue
  '#9d9d9d', //gray
]

export enum Color {
  Primary = '#488cff',
}
