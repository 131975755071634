import { LanguageStrings } from './base-strings'
import jsonData from '../../../data/comeptency-detail-en.json'

const frStrings: LanguageStrings = {
  /** Routes */
  'routes.home': '/',
  'routes.competency': '/compétence/:id',

  /** Page content */
  'home.title': 'Accueil',
  'home.content': 'Merci de visiter cet exemple de routage multilingue.',
  'lbl.competency': 'compétence',
  'competency.title': 'Sommaire',
  'competency.content':
    "En savoir plus sur l'article lui-même sur {medium} ou {devto}.",

  'error.title': 'Erreur',
  'error.content':
    'Cette page a décidé de prendre des vacances. Réessayez plus tard.',

  /** Links */
  'links.medium':
    'https://medium.com/prototyped/multi-language-routing-in-react-d7eb7a0688e9',
  'links.dev.to': 'https://dev.to/prototyp/multi-language-routing-in-react-k9l',
}
jsonData.Competencies['fr'].forEach((item: any) => {
  const competencyId: string = item.CompetencyID.toString()
  frStrings[
    `routes.competency.${competencyId}`
  ] = `/${frStrings['lbl.competency']}/${item.Name}`
})

export const fr = frStrings
